<template>
  <v-container class="px-2">
    <v-card flat class="mx-0 transparent">
      <v-card-text class="pa-0">
        <v-row dense>
          <v-col cols="6" md="3">
            <v-switch v-model="isBilling" :label="`Billing?`"></v-switch>
          </v-col>
          <v-col cols="6" md="3">
            <v-switch v-model="isShipping" :label="`Shipping?`"></v-switch>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="4">
            <v-select
              v-model="type"
              prepend-icon="mdi-view-list"
              :items="addressTypes"
              label="Address Type"
              outlined
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :error-messages="streetErrors"
              label="Street Address"
              ref="street"
              v-model="street"
              required
              type="text"
              :counter="250"
              prepend-icon="mdi-map-marker"
              @input="$v.street.$touch()"
              @blur="$v.street.$touch()"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              ref="country"
              v-model="country"
              prepend-icon="mdi-flag"
              :items="countriesList"
              label="Country"
              outlined
              item-text="country_name"
              item-value="iso"
            />
          </v-col>
          <v-col v-if="isus" cols="12" md="6">
            <v-select
              ref="state"
              v-model="state"
              prepend-icon="mdi-account-circle"
              :items="statesList"
              label="State"
              outlined
              item-text="state"
            />
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-text-field
              label="State/Province/Region"
              ref="region"
              v-model="region"
              type="text"
              :counter="250"
              prepend-icon="mdi-map-marker"
              @blur="region = capitalize(region)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              :error-messages="cityErrors"
              label="City"
              ref="city"
              v-model="city"
              required
              type="text"
              :counter="250"
              prepend-icon="mdi-map-marker"
              @input="$v.city.$touch()"
              @blur="cityChanged"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :error-messages="zipErrors"
              label="Zip/Postal Code"
              ref="zip"
              v-model="zip"
              required
              type="text"
              :counter="10"
              prepend-icon="mdi-map-marker"
              @input="$v.zip.$touch()"
              @blur="$v.zip.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-0 pb-0">
        <v-spacer />
        <BaseActionButton
          class="charcoal paper--text"
          :large="!isPhone"
          v-bind="$attrs"
          :disabled="this.$v.$anyError"
          @clickedThis="saveAddress"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { isValidZip } from '@/validators'
import axios from 'axios'
import addressTypes from '@/json/addresstypes.json'
import util from '@/mixins/util.js'

export default {
  props: {
    accountInfo: Object
  },
  mixins: [validationMixin, util],
  data: () => ({
    street: '',
    country: '',
    state: '',
    region: '',
    city: '',
    zip: '',
    type: '',
    isPrimary: true,
    isBilling: true,
    isShipping: true,
    statesList: [],
    countriesList: [],
    citiesList: [],
    addressTypes: addressTypes,
    addressid: null
  }),
  mounted() {
    this.loadCountries()
    this.loadStates()
    this.addressid = this.accountInfo.addressid
    this.street = this.accountInfo.street
    this.country = this.accountInfo.country
    this.state = this.accountInfo.state
    this.city = this.accountInfo.city
    this.zip = this.accountInfo.zip
    this.type = this.accountInfo.address_type
    this.isBilling = this.accountInfo.is_billing_address == 'Yes'
    this.isShipping = this.accountInfo.is_shipping_address == 'Yes'
  },
  validations: {
    street: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(250)
    },
    country: {
      required
    },
    city: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    zip: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(10),
      isValidZip: isValidZip
    },
    state: {
      required
    }
  },
  computed: {
    streetErrors() {
      const errors = []
      if (!this.$v.street.$dirty) return errors
      !this.$v.street.maxLength &&
        errors.push('Street must be at most 250 characters long')
      !this.$v.street.required && errors.push('Street is required.')
      return errors
    },
    countryErrors() {
      const errors = []
      if (!this.$v.country.$dirty) return errors
      this.country.length == 0 && errors.push('Country is required.')
      return errors
    },
    stateErrors() {
      const errors = []
      if (!this.$v.state.$dirty) return errors
      !this.$v.state.required && errors.push('State is required.')
      return errors
    },
    cityErrors() {
      const errors = []
      if (!this.$v.city.$dirty) return errors
      !this.$v.city.maxLength &&
        errors.push('City must be at most 250 characters long')
      !this.$v.city.required && errors.push('City is required.')
      return errors
    },
    zipErrors() {
      const errors = []
      if (!this.$v.zip.$dirty) return errors
      !this.$v.zip.required && errors.push('Zip is required.')
      !this.$v.zip.isValidZip && errors.push('Invalid zipcode')

      return errors
    },
    isus() {
      return this.country == 'US'
    }
  },
  methods: {
    cityChanged() {
      this.$v.city.$touch()
      this.city = this.capitalize(this.city)
    },
    loadCountries() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/util/countries/all', {})
          .then(response => {
            if (response.status == 200) {
              this.countriesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadStates() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/util/states/all', {})
          .then(response => {
            if (response.status == 200) {
              this.statesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadCities(term) {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/util/cities/' + term, {})
          .then(response => {
            if (response.status == 200) {
              this.citiesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveAddress() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        const address = {
          id: this.addressid,
          street: this.street,
          city: this.city,
          state: this.state,
          zip: this.zip,
          country: this.country,
          type: this.type,
          is_primary: this.isPrimary ? 'Yes' : 'No',
          is_shipping: this.isShipping ? 'Yes' : 'No',
          is_billing: this.isBilling ? 'Yes' : 'No',
          is_forward: 'No'
        }
        if (this.addressid != null) {
          return axios
            .put(this.$store.state.config.baseURL + '/users/address', address)
            .then(response => {
              if (response.status == 200) {
                this.addressid = response.data.data.addressid
                this.$emit('updateInfo')
                this.$store.dispatch(
                  'notification/addNotification',
                  'Address was succesfully saved.',
                  2000,
                  true,
                  {
                    root: true
                  }
                )
              } else {
                this.$store.dispatch(
                  'notification/addErrors',
                  response.data.errors,
                  5000,
                  {
                    root: true
                  }
                )
                return
              }
            })
        } else {
          return axios
            .post(this.$store.state.config.baseURL + '/users/address', address)
            .then(response => {
              if (response.status == 200) {
                this.addressid = response.data.data.addressid
                this.$emit('updateInfo')
                this.$store.dispatch(
                  'notification/addNotification',
                  'Address was succesfully saved.',
                  2000,
                  true,
                  {
                    root: true
                  }
                )
              } else {
                this.$store.dispatch(
                  'notification/addErrors',
                  response.data.errors,
                  5000,
                  {
                    root: true
                  }
                )
                return
              }
            })
        }
      }
    }
  }
}
</script>
